import { createApp } from 'vue'
import i18n from '@/i18n'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入 element-plus
import installElementPlus from './plugins/element'
// 导入全局样式
import './styles/index.scss'
// 导入权限控制模块
import './permission'
// 导入 svgIcon
import installIcons from '@/icons'
// filter
import installFilter from '@/filters'
// 导入指令
import installDirective from '@/directives'

const app = createApp(App)

app.config.warnHandler = () => null

installFilter(app)
installDirective(app)

app
  .use(installElementPlus)
  .use(i18n)
  .use(installIcons)
  .use(store)
  .use(router)
  .mount('#app')
