import { deCrypto, enCrypto } from './crypto'

export function createLocalStorage(options) {
  const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7

  const { expire, crypto } = Object.assign(
    {
      expire: DEFAULT_CACHE_TIME,
      crypto: true
    },
    options
  )

  function set(key, data) {
    const storageData = {
      data,
      expire: expire !== null ? new Date().getTime() + expire * 1000 : null
    }

    const json = crypto ? enCrypto(storageData) : JSON.stringify(storageData)
    window.localStorage.setItem(key, json)
  }

  function get(key) {
    const json = window.localStorage.getItem(key)
    if (json) {
      let storageData = null

      try {
        storageData = crypto ? deCrypto(json) : JSON.parse(json)
      } catch {
        // Prevent failure
      }

      if (storageData) {
        const { data, expire } = storageData
        if (expire === null || expire >= Date.now()) {
          return data
        }
      }

      remove(key)
      return null
    }
  }

  function remove(key) {
    window.localStorage.removeItem(key)
  }

  function clear() {
    window.localStorage.clear()
  }

  return {
    set,
    get,
    remove,
    clear
  }
}

export const ls = createLocalStorage()

export const ss = createLocalStorage({ expire: null, crypto: false })

export const cs = createLocalStorage({ expire: null, crypto: true })
