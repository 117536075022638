import { cs } from './storagePro'

export const phoneNumReg = /^((\+86)|86)?1[3-9]\d{9}$/

export const getRoundColor = () => {
  const colors = ['success', 'info', 'warning', 'danger']
  const randomIndex = Math.floor(Math.random() * colors.length)
  return colors[randomIndex]
}

// 查询数组中最大项
export function findMaxItem(arr, field) {
  let maxItem = {}
  let maxWeight = -Infinity
  if (!Array.isArray(arr)) {
    return maxItem
  }
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][field] > maxWeight) {
      maxWeight = arr[i][field]
      maxItem = arr[i]
    }
  }
  return maxItem
}

export function getLoginInfo() {
  return cs.get('LOGIN_INFO')
}

export function setLoginInfo(info) {
  return cs.set('LOGIN_INFO', info)
}

export function deepClone(obj, hash = new WeakMap()) {
  if (obj === null) return null
  if (typeof obj !== 'object') return obj

  // 如果对象是日期类型或正则对象，直接返回新的实例
  if (obj instanceof Date) return new Date(obj)
  if (obj instanceof RegExp) return new RegExp(obj)

  // 如果该对象已经被记录，则直接返回之前的结果，以处理循环引用
  if (hash.has(obj)) return hash.get(obj)

  // 使用 Object.getPrototypeOf 获取原型
  const t = Object.getPrototypeOf(obj)
  const cloneObj = Object.create(t)

  // 记录当前的对象，避免循环引用
  hash.set(obj, cloneObj)

  Object.keys(obj).forEach(key => {
    cloneObj[key] = deepClone(obj[key], hash)
  })

  return cloneObj
}
