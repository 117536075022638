import layout from '@/layout'

export default {
  path: '/order',
  component: layout,
  redirect: '/order/list',
  name: 'orderList',
  meta: { title: 'order', icon: 'article' },
  children: [
    {
      path: '/order/list',
      component: () =>
        import(
          /* webpackChunkName: "order-list" */ '@/views/order/list'
        ),
      meta: {
        title: 'orderList',
        icon: 'article-ranking'
      }
    },
    // {
    //   path: '/order/detail',
    //   name: 'orderSearch',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "order-list" */ '@/views/order/detail'
    //     ),
    //   meta: {
    //     title: 'orderSearch'
    //   }
    // },
    {
      path: '/order/detail/:orderId',
      name: 'orderSearch',
      component: () =>
        import(
          /* webpackChunkName: "order-list" */ '@/views/order/detail'
        ),
      meta: {
        title: 'orderSearch'
      }
    }
  ]
}
