import { login, getUserInfo } from '@/api/sys'
// import md5 from 'md5'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
import router, { resetRouter } from '@/router'
import { setTimeStamp } from '@/utils/auth'
import { findMaxItem, setLoginInfo, getLoginInfo } from '@/utils/utils'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {},
    maxlevel: {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setMaxlevel(state, userInfo) {
      state.maxlevel = findMaxItem(userInfo.role, 'weight')
    }
  },
  actions: {
    login(context, userInfo) {
      const { mobile, password } = userInfo
      return new Promise((resolve, reject) => {
        login({
          mobile,
          password
        })
          .then(data => {
            this.commit('user/setToken', data)
            // 保存登录时间
            setTimeStamp()
            // 记录登陆密码(加密存储)
            setLoginInfo({
              mobile,
              password
            })
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async getUserInfo(context) {
      const res = await getUserInfo()
      this.commit('user/setUserInfo', res)
      this.commit('user/setMaxlevel', res)
      return res
    },
    logout() {
      const loginInfo = getLoginInfo()
      resetRouter()
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      this.commit('app/initTagsViewList')
      removeAllItem()
      setLoginInfo(loginInfo)
      router.push('/login')
    }
  }
}
