<template>
  <!-- <el-icon
    v-if="icon.includes('el-icon')"
    class="sub-el-icon"
    :class="icon"
  ></el-icon> -->
  <el-icon v-if="icon.includes('el-icon')" :size="20" class="sub-el-icon">
    <component :is="elIocn"></component>
  </el-icon>
  <svg-icon v-else :icon="icon"></svg-icon>
  <span>{{ generateTitle(title) }}</span>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { generateTitle } from '@/utils/i18n'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  }
})

const elIocn = computed(() => {
  let iconName = ''
  if (props.icon.includes('el-icon')) {
    iconName = props.icon.split('-').pop()
  }
  return iconName
})
</script>

<style lang="scss" scoped></style>
