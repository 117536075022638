import layout from '@/layout'

export default {
  path: '/kami',
  component: layout,
  redirect: '/kami/list',
  name: 'kamiList',
  meta: { title: 'kami', icon: 'article' },
  children: [
    {
      path: '/kami/list',
      component: () =>
        import(
          /* webpackChunkName: "kami-list" */ '@/views/kami/list'
        ),
      meta: {
        title: 'kamiList',
        icon: 'article-ranking'
      }
    },
    {
      path: '/kami/search',
      name: 'kamiSearch',
      component: () =>
        import(
          /* webpackChunkName: "kami-list" */ '@/views/kami/search'
        ),
      meta: {
        title: 'kamiSearch',
        icon: 'article-ranking'
      }
    }
  ]
}
