import dayjs from 'dayjs'
import rt from 'dayjs/plugin/relativeTime'
// 语言包
import 'dayjs/locale/zh-cn'
import store from '@/store'

const dateFilter = (val, format = 'YYYY-MM-DD') => {
  if (!isNaN(val)) {
    val = parseInt(val)
  }

  return dayjs(val).format(format)
}

const orderFilter = (status) => {
  // 订单状态， 0 待支付  1 支付成功  2 支付失败 3 已退款
  let text = ''
  switch (status) {
    case 0:
      text = '待支付'
      break
    case 1:
      text = '支付成功'
      break
    case 2:
      text = '支付失败'
      break
    case 3:
      text = '已退款'
      break

    default:
      text = '未知'
      break
  }
  return text
}

// 加载相对时间插件
dayjs.extend(rt)
function relativeTime(val) {
  if (!isNaN(val)) {
    val = parseInt(val)
  }
  return dayjs()
    .locale(store.getters.language === 'zh' ? 'zh-cn' : 'en')
    .to(dayjs(val))
}

export default app => {
  app.config.globalProperties.$filters = {
    dateFilter,
    orderFilter,
    relativeTime
  }
}
